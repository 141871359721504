.newsletter-content {
  width: 100%;
  background-color: #f5f6fa;
  padding-top: 80px;
  padding-bottom: 80px;
}

#newsletter {
  width: 550px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  font-size: 20px;
  background-color: transparent;
  border: 3px solid #a1a0a0;
  outline: none;
  color: #00141d;
  margin-top: 25px;
  padding-right: 15px;
  padding-left: 15px;
  transition: 285ms ease-in-out;
  box-sizing: border-box;
  outline: 1px solid transparent;
}

.newsletter-headline {
  font-size: 40px;
  line-height: 1;
}

#newsletter:focus {
  transition: 85ms ease-in-out;
  border: 3px solid #585858;
}

.subscribe-btn {
  width: 240px;
  height: 60px;
  margin-top: 25px;
  outline: none;
  border-radius: 10px;
  font-size: 20px;
  transition: 285ms ease-in-out;
  background: #1dc579;
  border: 3px solid #1dc579;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-btn:hover {
  background: #f5f6fa;
  color: #1dc579;
  transition: 285ms ease-in-out;
}

.btn-height-estimated {
  height: 64px;
}

.text-size {
  font-size: 20px;
  line-height: 24px;
  color: #00141d;
  font-weight: 600;
}

.newsletter-res-message {
  display: none;
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .newsletter-content {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 620px) {
  #newsletter {
    width: 90% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    font-size: 20px;
    margin-top: 25px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .newsletter-content p {
    text-align: center;
    max-width: 96%;
  }

  .newsletter-headline {
    font-size: 35px;
  }
}
