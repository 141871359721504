:root {
  --green: #1cdb86;
  --white: #ffffff;
  --bg-color: #ffffff;
  --dimmed: #00141d;
  --txt-color: #00141d;
  --header-shadow: rgba(27, 218, 133, 0.2);
  --footer-shade: #00141d;
  color-scheme: light only;
}

/* html {
  scroll-behavior: smooth;
} */

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@keyframes fadeInJobs {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

body {
  background: #fff !important;
  -webkit-font-smoothing: antialiased;
  font-family: "Publica Sans", sans-serif;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background: #1cdb861a;
  z-index: 1;
  overflow-x: hidden !important;
}

* {
  box-sizing: border-box;
  scroll-margin-top: 95px;
  -webkit-tap-highlight-color: transparent;
}

.txt-color {
  color: #00141d;
  transition: 285ms ease-in-out;
}

.white-txt {
  color: #fff;
}

.green-color-txt {
  color: #1cdb86;
}

.black-color-txt {
  color: #00141d;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #00141d;
}

::-webkit-scrollbar-thumb {
  background: #1dc579;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1dc579;
}

::selection {
  background-color: #1dc579 !important;
}

.show-to-top-button {
  transform: scale(1);
}

.hide-to-top-button {
  transform: scale(0);
}

.scroll-to-top-btn,
.back-to-home {
  position: fixed;
  display: flex;
  bottom: 20px;
  right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 9px;
  z-index: 9998;
  background: rgba(28, 219, 134, 0.3);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.back-to-home {
  position: static;
  width: 350px;
  height: 60px;
  font-size: 22px;
  font-weight: 600;
}

.scroll-to-top-btn:hover,
.back-to-home:hover {
  background: rgba(28, 219, 134, 0.4);
  transition: 285ms ease-in-out;
}

.scroll-to-top-btn:active,
.back-to-home:active {
  transition: 155ms ease-in-out;
  background: rgba(28, 219, 134, 0.5);
  border: 4px solid #1cdb86;
}

.not-found-logo {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 250px;
}

.list-items-custom {
  transition: 500ms ease-in-out;
  overflow: hidden;
}

.list-items-custom:not(.active) {
  display: none;
  transition: 500ms ease-in-out;
}

.loading-spinner {
  color: #00141d;
  font-size: 35px;
  animation: spinner 700ms linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .banner-projects {
   background-image: url("../assets/images/projects-2.webp"); 
} */

@media only screen and (max-width: 950px) {
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  html {
    overflow-x: hidden !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.green-text-selection::selection {
  background-color: #06202b !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #e8f4ef inset;
}
