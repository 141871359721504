.Apply-button-mobile {
  display: none;
  transition: 385ms ease-in-out;
}

.Apply-btn-mob-show {
  display: flex;
  bottom: 0px;
}

.Apply-btn-mob-hide {
  display: none;
  bottom: -50px;
}

.apply-form-mobile {
  max-height: 100vh;
  height: fit-content;
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
  padding: 30px 13px;
  overflow-y: auto;
  transition: 285ms ease-in-out;
  visibility: hidden;
}

.show-apply-form-mobile {
  transform: translateY(0%) !important;
  transition: 285ms ease-in-out;
}

.hide-apply-form-mobile {
  transform: translateY(100%) !important;
  transition: 285ms ease-in-out;
}

.close-apply-form {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 50px;
  color: #00141d;
}

@media only screen and (max-width: 950px) {
  .apply-form-mobile {
    visibility: visible;
  }

  .Apply-button-mobile {
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0px;
    left: 9px;
    background-color: #1cdb86;
    padding: 6px 20px;
    font-weight: bold;
    font-size: 20px;
    color: #ffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .mobile-form-apply-field-wrapper {
    flex-direction: column !important;
    margin-top: 0px !important;
  }

  .apply-form-mobile-input {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .uppload-resume {
    margin-top: 5px;
    height: 65px;
  }

  #resume-upload {
    height: 65px;
  }

  .form-field {
    height: 50px;
    font-size: 15px;
  }

  .send-application {
    min-height: 55px !important;
  }

  .phone-example {
    position: initial;
  }
}
