.main-capsule {
  height: 800px;
  width: 520px;
  background: #f0f0f0;
  /* background-image: url("../../../assets/images/gray-workers.webp");
  background-repeat: no-repeat;
  background-position: 20%;
  background-size: contain; */
  border-radius: 200px;
  position: relative;
  /* background-attachment: fixed;
  background-position: top left; */
  background-color: #00141d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-animation {
  width: 90%;
}

.capsule-1 {
  height: 400px;
  width: 200px;
  background: #1cdb86;
  border-radius: 100px;
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;

  /*HIDE GREEN FLOATING CAPSULE*/
  display: none;
}
.capsule-2 {
  height: 40%;
  width: 150px;
  background-color: #00141d;
  border-radius: 100px;
  position: absolute;
  left: -40px;
  bottom: -30px;
}

.projects-table {
  width: 100%;
}

.our-projects {
  margin-right: 0;
  width: 400px;
}

.project-list-item {
  color: #00141d;
  font-size: 18px;
  font-weight: 600;
  background-color: #f5f6fa;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 7px;
  padding: 15px;
  transition: 285ms ease-in-out !important;
  display: block;
}

.project-list-item:hover {
  transition: 285ms ease-in-out !important;
  background-color: #e8eaee;
}

.hr-line {
  background-color: #1cdb86;
  border-radius: 5px;
  height: 2px;
  width: 100%;
}

.about-us-text {
  max-width: 25%;
  text-align: justify;
}

.about-us-desc {
  font-size: 20px;
  line-height: 24px;
}

.bottom-about-us-txt {
  margin-top: 50px;
}

.bottom-about-us-txt p {
  max-width: 900px;
  text-align: center;
  display: block;
  border: 5px solid #1cdb86;
  border-radius: 200px;
  padding: 40px;
  font-weight: bold;
}
.about-us-arrow-animation {
  transition: 285ms ease-in-out;
  transform: translateX(-10px);
  display: initial;
  opacity: 0;
 }


.project-list-item:hover .about-us-arrow-animation {
  transition: 285ms ease-in-out;
  opacity: 1;
  transform: translateX(5px);
}

@media only screen and (max-width: 1700px) and (min-width: 1500px) {
  .about-us-text {
    max-width: 24%;
    min-width: 24%;
  }

  .main-capsule {
    height: 100vh;
  }
}

@media only screen and (max-width: 1500px) {
  .main-capsule {
    height: 85vh;
    width: 450px;
  }

  .our-projects {
    width: 350px;
    margin-right: 0px;
  }

  .project-list-item {
    font-size: 15px;
  }

  .main-capsule .capsule-1 {
    height: 300px;
    width: 170px;
    top: initial;
    bottom: 0;
  }

  .about-us-text {
    max-width: initial;
  }

  .about-us-text,
  .our-projects {
    margin-left: 10px !important;
    margin-right: 30px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .about-us {
    flex-direction: column;
    padding: 0;
    height: 100%;
    display: flex;
  }
  .about-us-text {
    max-width: 100%;
    text-align: center;
    margin: auto;
  }
  .about-us-text h2 {
    font-size: 70px;
  }
  .about-us-text p {
    width: 80%;
    margin: 27px auto;
  }
  .capsule-image {
    margin: 0;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .capsule-projects {
    display: flex;
    justify-content: space-around;
  }
  .main-capsule {
    height: 80vh;
    width: 480px;
    /* background-size: cover;
    background-position: top; */
  }
  .main-capsule .capsule-1 {
    height: 300px;
    width: 150px;
    top: initial;
    bottom: 0;
  }
  .our-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px !important;
    text-align: center;
  }
  .projects-text h2 {
    font-size: 40px;
  }
  .hr-line {
    width: 100%;
    margin: auto;
  }
  .projects-table {
    width: 100%;
  }
  .about-us-text,
  .our-projects {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1180px) {
  .about-us-desc {
    font-size: 20px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 1000px) {
  .hr-line {
    width: 100%;
  }

  .bottom-about-us-txt p {
    max-width: 90%;
  }

  .about-us {
    margin-left: auto;
    margin-right: auto;
  }

  .about-us-text {
    width: 100%;
    text-align: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .our-projects {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 30px !important;
    text-align: center;
  }

  .about-us-text p {
    width: 90% !important;
  }

  .capsule-projects {
    flex-direction: column;
  }

  .capsule-image {
    width: 100%;
  }

  .capsule-image .main-capsule {
    height: 400px;
    /* background-attachment: local; */
    width: 80%;
    overflow: hidden;
    border-radius: 30px;
  }

  .capsule-1 {
    height: 130px;
    width: 70%;
    border-radius: 1000px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0px;
  }

  .capsule-2 {
    height: 60px;
    width: 60%;
    background-color: #00141d;
    border-radius: 100px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: -30px;
  }

  .capsule-image .main-capsule .capsule-1 {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .capsule-image .main-capsule {
    height: 300px;
    /* background-attachment: local; */
    width: 90%;
    overflow: hidden;
    border-radius: 30px;
  }

  .our-projects {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 600px) {
  .hr-line {
    width: 90%;
  }

  .capsule-1 {
    height: 40px;
    width: 70%;
    border-radius: 1000px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0px;
  }

  .our-projects {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px !important;
  }

  .bottom-about-us-txt p {
    border-radius: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .about-us {
    padding: 0 !important;
  }
  .capsule-image {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .projct-table-list {
    padding-top: 15px !important;
  }

  .capsule-image .main-capsule .capsule-1 {
    display: none;
  }

  .projects-table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .projct-table-list {
    width: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }

  .project-list-item {
    width: 91%;
    margin: 0;
    margin-top: 7px;
    font-size: 15px;
  }
}
