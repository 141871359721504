#open-positions {
  width: calc(100% - 950px);
}

@media only screen and (max-width: 1750px) and (min-width: 1500px) {
  #open-positions {
    width: calc(100% - 650px);
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1160px) {
  #open-positions {
    width: calc(100% - 530px);
  }
}

@media only screen and (max-width: 1160px) and (min-width: 950px) {
  #open-positions {
    width: calc(100% - 460px);
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  #open-positions {
    width: calc(100% - 650px);
  }
}