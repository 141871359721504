.jobs-banner {
  width: 100%;
  min-height: 100vh;
  background-color: #00141d;
  overflow: hidden;
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jobs-banner-data {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.jobs-banner-animation {
  width: 35%;
}

.jobs-banner-animation svg {
  cursor: default;
}

.jobs-banner-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.jobs-banner-text h1 {
  color: #1cdb86;
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 20px;
}

.jobs-banner-text p {
  color: #fff;
  font-size: 25px;
  max-width: 90%;
  text-align: center;
}

.c-1 {
  margin-top: 15px;
}

.fadeIn-jobs {
  animation: fadeInJobs 385ms ease-in-out;
}

.realted-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

.job-arrow {
  margin-left: 10px;
}

.realted-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  width: 260px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 22px;
  border-radius: 8px;
  background-color: #0c2c3b;
  transition: 285ms ease-in-out;
  border: 3px solid #0c2c3b;
}

.realted-links a:hover {
  background-color: #00141d;
  transition: 285ms ease-in-out;
  border: 3px solid #1cdb86;
}

@media only screen and (max-width: 1600px) {
  .realted-links a {
    width: 210px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
  }

  .jobs-banner-text h1 {
    font-size: 45px;
  }

  .jobs-banner-text p {
    font-size: 20px;
    max-width: 90%;
  }
}

@media only screen and (max-width: 1460px) {
  .jobs-banner-text .realted-links {
    justify-content: center;
    flex-direction: column;
  }
  .jobs-banner-text .realted-links a {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 1400px) {
  .jobs-banner-animation {
    width: 50%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 720px) {
  .jobs-banner-text h1 {
    font-size: 45px;
  }

  .jobs-banner-text p {
    font-size: 20px;
    max-width: 600px;
  }
}

@media only screen and (max-width: 720px) and (min-width: 500px) {
  .jobs-banner-text h1 {
    font-size: 35px;
  }

  .jobs-banner-text p {
    font-size: 20px;
    max-width: 80%;
  }
}

@media only screen and (max-width: 720px) {
  .jobs-banner-data {
    justify-content: center;
    flex-direction: column;
  }
  .jobs-banner-animation {
    margin-top: 70px;
  }
  .jobs-banner-text {
    width: 100%;
  }
  .jobs-banner-text .realted-links {
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .jobs-banner-text .realted-links a {
    margin: 10px 0;
  }

  .jobs-banner-text h1 { 
     margin-top: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 720px) {
  .jobs-banner-animation {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .jobs-banner-animation {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .jobs-banner-text h1 {
    font-size: 35px;
  }
  .jobs-banner-text p {
    font-size: 20px;
    max-width: 100%;
    margin-top: 10px;
  }
}
