.banner {
  /* background-image: url("../../../assets/images/projects.png"); */
  width: 100%;
  height: 100vh;
  animation: fadeInBanner 285ms ease-in-out;
}

#particles-js {
  /* background-image: url("../../../assets/images/grid.png"); */
  transform: translateZ(0);

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.content {
  position: absolute;
  background-clip: text;
  background-attachment: fixed;
}


.obelus-spinner {
  position: absolute;
  bottom: 10px;
  right: 10px;
  animation: spinner 1s;
  height: 100px;
  width: fit-content;
}

.title-link {
  position: relative;
  overflow: hidden;
  font-weight: 500;
  color: inherit;
  font-size: 160px;
  line-height: 160px;
  width: 1100px;
  text-align: center;
  color: white;
  padding-bottom: 20px;
  letter-spacing: 10px;
  animation: 
    typing 0.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg) scale(20);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: showTitle ease-in 1;
  -moz-animation: showTitle ease-in 1;
  animation: showTitle ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 350ms;
}

.fadeIn.first {
  animation-delay: 50ms;
}

.fadeIn.second {
  animation-delay: 100ms;
}

.fadeIn.third {
  animation-delay: 200ms;
}

.fadeIn.fourth {
  animation-delay: 300ms;
}

.fadeIn.fifth {
  animation-delay: 400ms;
}

@keyframes showTitle {
  from {
    transform: translateY(30%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.scroll-down-banner {
  font-size: 45px;
  position: absolute;
  bottom: 20px;
  color: white;
  animation: scrollDown 1s ease-in-out infinite;
}

@keyframes scrollDown {
   0% {
    transform: translateY(-25%);
   }
   50% {
    transform: translate(0%);
   }
   100% {
    transform: translateY(-25%);
   }
}

.banner-desc-txt {
  font-size: 24px;
  line-height: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 800px) and (min-width: 500px) {
  .title-link {
    font-size: 100px;
    line-height: 100px;
    width: unset;
    padding-bottom: 20px;
    letter-spacing: 10px;
  }

  .banner-desc-txt {
    font-size: 20px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 500px){
  .title-link {
    font-size: 70px;
    line-height: 70px;
    width: unset;
    padding-bottom: 20px;
    letter-spacing: 10px;
  }

  .banner-desc-txt {
    font-size: 17px;
    line-height: 24px;
  }

  .obelus-spinner {
    width: 100px;
    height: fit-content;
    right: calc((100% - 100px) / 2);
  }
}
