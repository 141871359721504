.job-introudction-wrp {
  margin-top: 50px;
  width: 90% !important;
}

.headline-in-job-introductions {
  color: #00141d;
  font-size: 45px;
  line-height: 1;
  text-align: left;
  font-weight: bold;
  max-width: 500px;
}

.job-introductions-text {
  font-size: 20px;
  line-height: 28px;
  max-width: 600px;
  color: #00141d;
  margin-top: 35px;
  font-weight: 500;
  text-align: justify;
}

.lets-connect-btn {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  padding: 13px 20px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 35px;
  transition: 285ms ease-in-out;
  background-color: #00141d;
  color: #f9f9fa;
}

.job-introductions-width-2 {
  width: 70%;
}

.job-img-spacing {
  margin-right: 50px;
}

.obelus-symbol {
  height: 40px;
  margin-right: 10px;
}

.lets-connect-btn:hover {
  transform: translateY(-5px);
  transition: 285ms ease-in-out;
}

.lets-connect-btn:active {
  transform: scale(1.1);
}

.job-introduction-img {
  width: 500px;
  height: fit-content;
  /* max-height: 600px; */
  border-radius: 20px;
}

/* .job-introduction-img {
  max-height: 310px !important;
} */

.life-at-obelus {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f5f6fa;
}

.benefit-items-wrapper {
  width: 1150px;
  margin-top: 60px;
}

.benefit-item {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  display: inline-flex;
  width: 47%;
  height: 320px;
}

.first-icon,
.second-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
}

.benefit-items-wrapper {
  display: inline-block;
}

.first-icon {
  background-color: #00141d;
  color: white;
}

.second-icon {
  background-color: #1cdb86;
  margin-left: -10px;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefit-title {
  color: #00141d;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
}

.benefit-desc {
  font-size: 20px;
  line-height: 24px;
  color: #858a8f;
}

.life-at-obelus-wrapper {
  width: 80%;
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .job-introduction-img {
    width: 400px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1210px) and (min-width: 950px) {
  .job-introduction-img {
    width: 400px;
    margin-right: 0px;
    margin-left: 25px;
  }
}

@media only screen and (max-width: 950px) {
  .job-introudction-wrp {
    flex-direction: column;
  }

  .job-introudction {
    justify-content: center;
    align-items: center;
  }

  .lets-connect-btn {
    margin-left: auto;
    margin-right: auto;
  }

  .job-introduction-img {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 50px;
  }

  .life-at-obelus-wrapper {
    width: 90%;
  }

  .benefit-items-wrapper {
    width: 100%;
  }

  .benefit-item {
    width: 100%;
    min-height: 320px;
    height: fit-content;
    margin-right: 0px;
    margin-left: 0px;
  }

  .headline-in-job-introductions {
    text-align: center;
    font-size: 35px;
  }

  .job-introductions-text {
    text-align: center;
    font-weight: normal !important;
  }
}
