.process-wrp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.process-title {
  color: #00141d;
  font-weight: bold;
  font-size: 45px;
  line-height: 1;
}

.approach-title {
  text-align: center;
  font-size: 20px;
  line-height: 27px;
  color: #00141d;
  max-width: 800px;
  margin-top: 25px;
}

.steps-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #00141d;
  border-radius: 10px;
  left: 50%;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 20px;
  position: relative;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #f5f6fa;
  background-color: #f5f6fa;
  border-radius: 8px;
  padding: 20px;
  position: relative;
}
.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #f5f6fa;
  border-right: 0 solid #f5f6fa;
  border-bottom: 15px solid transparent;
  overflow: hidden;
  content: " ";
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #f5f6fa;
  border-right: 0 solid #f5f6fa;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-body > p {
  font-size: 20px;
  line-height: 27px;
}

.timeline-badge {
  background-color: #1cdb86 !important;
}

.timeline-mobile {
  display: none;
}

@media only screen and (max-width: 950px) {
  .timeline-mobile {
    display: flex;
  }

  .timeline {
    margin-bottom: 40px;
  }

  .timeline-inverted {
    display: none;
  }

  .timeline:before {
    right: 2%;
    left: unset;
  }

  .timeline > li > .timeline-badge {
    left: unset;
    right: -5%;
  }

  .timeline > li > .timeline-panel {
    width: 84%;
  }
}

@media only screen and (min-width: 550px) and (max-width: 950px) {
  .timeline > li > .timeline-badge {
    left: unset;
    right: -10px;
  }

  .timeline > li > .timeline-panel {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .approach-title {
    width: 90%;
  }
}
