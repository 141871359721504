.contact-wrapper {
  width: 600px !important;
}

.contact-blocks-wrapper {
  padding-top: 56px;
  padding-bottom: 35px;
}

.contact-content-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact-field {
  font-size: 20px;
  background-color: transparent;
  font-size: 20px;
  height: 60px;
  padding-right: 15px;
  padding-left: 15px;
  border: 3px solid #c0c0c0;
  color: #00141d;
  outline: 1px solid transparent;
  transition: 285ms ease-in-out;
  margin-top: 20px;
  border-radius: 10px;
}

.contact-headline {
  font-size: 50px;
  font-weight: bold;
}

.contact-field:focus {
  transition: 285ms ease-in-out;
  border: 3px solid #1cdb86;
  outline: 1px solid #1cdb86;
}

.contact-email {
  width: 60%;
}

.contact-name {
  width: calc(40% - 15px);
  margin-right: 15px;
}

.contact-subject {
  width: 100%;
}

.contact-message {
  resize: none;
  width: 100%;
  height: 200px;
  padding-top: 10px;
}

.send-btn {
  font-size: 27px;
  width: 250px;
  height: 65px;
  background-color: #1dc579;
  border: 3.5px solid #1dc579;
  color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: 285ms ease-in-out !important;
}

.social-media-block {
  padding: 10px;
  background-color: #1cdb86;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.social-media-block:hover {
  transition: 285ms ease-in-out;
  background-color: #fff;
  color: #1cdb86;
  transform: scale(1.1);
}

.send-btn:hover {
  background-color: #ffff;
  color: #1cdb86;
  transition: 285ms ease-in-out;
}

.send-btn:active {
  border: 6px solid #1cdb86;
}

.contact-desc {
  font-weight: 500;
  margin-top: -5px;
  padding-top: 0px;
  font-size: 20px;
  line-height: 28px;
}

.other-contact {
  width: 450px;
  border: 2px solid #1dc579;
  border-radius: 20px;
  position: sticky;
  top: 110px;
  margin-left: 150px;
}

.contact-other-headline {
  font-size: 50px;
  font-weight: bold;
}

.contact-other-wrp {
  margin-left: 55px;
  margin-top: 55px;
}

.other-info {
  font-size: 18px;
  font-weight: 500;
  margin-left: 15px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: 285ms ease-in-out;
}

.other-info:hover {
  transition: 285ms ease-in-out;
  border-bottom: 2px solid #1dc579;
}

.social-media {
  width: calc(100% + 1px);
  margin-left: -1px;
  height: 100px;
  margin-top: 50px;
  background: #1cdb86;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  margin-bottom: -1px;
}

.lets-connect-headline {
  font-size: 50px;
  line-height: 1;
  font-weight: bold;
  color: #00141d;
}

.connect-description {
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
  font-style: normal;
  text-align: center;
  max-width: 800px;
  color: #00141d;
}

.contact-block {
  background-color: #f5f6fa;
  width: 450px;
  height: 320px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 25px;
  transition: 285ms ease-in-out !important;
  cursor: pointer;
}

.contact-block:first-child {
  margin-right: 25px;
}

.email-block-icon {
  background-color: #1cdb86;
  padding: 15px;
  border-radius: 50%;
  color: white;
  margin-bottom: 25px;
  margin-top: 10px;
}

.contact-block-headline {
  font-size: 25px;
  color: #00141d;
  font-weight: bold;
}

.contact-block-description {
  font-size: 18px;
  margin-top: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: #00141d;
}

.connect-button {
  font-size: 21px;
  font-weight: bold;
  margin-top: 10px;
  color: rgb(0, 20, 29);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.connect-button P {
  margin-right: 9px;
}

.connect-button-icon {
  font-size: 35px;
  transition: 285ms ease-in-out;
}

.contact-block:hover .connect-button-icon {
  margin-left: 15px !important;
  transition: 285ms ease-in-out;
}

.contact-block:hover {
  transition: 285ms ease-in-out;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.contact-res-message {
  display: none;
}

@media only screen and (max-width: 1550px) and (min-width: 800px) {
  .lets-connect-headline {
    font-size: 40px;
  }

  .connect-description {
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
  }

  .other-contact {
    width: 400px;
    top: 110px;
    margin-left: 50px;
  }

  .contact-headline {
    font-size: 40px;
  }

  .contact-desc {
    font-weight: 500;
    margin-top: -5px;
    padding-top: 0px;
    font-size: 17px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 500px) {
  .lets-connect-headline {
    font-size: 35px;
  }

  .connect-description {
    width: 90%;
  }

  .connect-description {
    font-size: 20px;
    line-height: 27px;
    margin-top: 20px;
    max-width: 85%;
  }
}

@media only screen and (max-width: 500px) {
  .lets-connect-headline {
    font-size: 35px;
  }

  .connect-description {
    width: 90%;
  }

  .connect-description {
    font-size: 20px;
    line-height: 27px;
    margin-top: 20px;
    max-width: 90%;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .contact-form-margins {
    margin-right: 50px;
  }

  .contact-field {
    font-size: 18px;
    height: 55px;
    margin-top: 16px;
    border-radius: 8px;
  }

  .contact-headline {
    font-size: 40px;
  }

  .contact-message {
    height: 180px;
  }

  .send-btn {
    height: 60px;
    width: 230px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 970px) {
  .contact-blocks-wrapper {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }

  .contact-block {
    max-width: 90%;
  }

  .contact-block:first-child {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1150px) {
  .contact-content-wrapper {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .contact-form-margins {
    margin-right: 0px;
  }

  .other-contact {
    position: static !important;
    margin-top: 50px;
    margin-left: 0px;
  }
  .contact-wrapper {
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
  }

  .contact-desc {
    text-align: center;
    margin-top: 4px;
  }

  .contact-headline {
    text-align: center;
  }
}

@media only screen and (max-width: 1150px) and (min-width: 650px) {
  .contact-form-margins,
  .other-contact {
    width: 600px !important;
  }
}

@media only screen and (max-width: 650px) {
  .contact-form-margins,
  .other-contact {
    width: 90% !important;
  }

  .contact-headline {
    font-size: 35px;
  }

  .contact-field {
    height: 50px;
    border-radius: 8px;
  }

  .contact-name-and-email-wrapper {
    flex-direction: column !important;
  }

  .contact-name,
  .contact-email {
    width: 100%;
  }

  .contact-message {
    height: 200px;
  }

  .contact-field {
    border: 2px solid #c0c0c0;
  }

  .contact-field:focus {
    border: 2px solid #1cdb86;
    outline: 1px solid #1cdb86;
  }
}

@media only screen and (max-width: 360px) {
  .contact-block {
    height: 330px;
  }

  .connect-button-icon {
    font-size: 25px;
  }
}
