.header {
  transition: 285ms ease-in-out;
  z-index: 9999;
  /* border-bottom: 01px solid rgba(255, 255, 255, 0.2); */
  padding: 13px 100px;
}

.header-white {
  background: #ffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
  transition: 285ms ease-in-out;
}

.header-logo {
  width: auto;
}

.link {
  font-size: 19px;
  transition: 285ms ease-in-out;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  border-radius: 7px;
  border: 4px solid transparent;
  transition: 285ms ease-in-out;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 5px;
}

.inverted-header {
  background-color: transparent;
}

.active-link {
  transition: 285ms ease-in-out;
  background: rgba(28, 219, 134, 0.3);
}

.link:hover {
  transition: 285ms ease-in-out;
  background: rgba(28, 219, 134, 0.3);
}

.link:active {
  transition: 75ms ease-in-out;
  background: #1cdb8666;
  border: 4px solid #1cdb86;
}

.header-icon-burger {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .header {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .link {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 3px;
    border: 3px solid transparent;
  }

  .link:active {
    transition: 155ms ease-in-out;
    background: #1cdb8666;
    border: 3px solid #1cdb86;
  }

  .header-logo {
    height: 55px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .header-icon-burger {
    display: block;
  }
  .link {
    display: none;
  }
  .header {
    justify-content: space-between;
    padding-right: 13px !important;
    padding-left: 13px !important;
  }
}

@media only screen and (max-width: 600px) {
  .header-logo {
    height: 45px !important;
  }
}
