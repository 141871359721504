.Apply-form {
  position: sticky;
  top: 100px;
  width: 550px !important;
}

.form-field {
  background-color: transparent;
  font-size: 13px;
  height: 40px;
  padding: 5px 13px;
  border: 2px solid #c0c0c0;
  color: #00141d;
  outline: none;
  outline: 1px solid transparent;
  transition: 285ms ease-in-out;
  border-radius: 5px;
}

.form-field:focus {
  transition: 285ms ease-in-out;
  border: 2px solid #1cdb86;
  outline: 1px solid #1cdb86;
}

.input-form-name {
  color: #00141d;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: uppercase;
}

select.form-field > option {
  font-size: 16px;
}

.send-application {
  width: 100%;
  text-transform: uppercase;
  padding-right: 25px;
  padding-left: 25px;
  height: 50px;
  margin-top: 20px;
  font-weight: 700;
  outline: none;
  border-radius: 4px;
  font-size: 20px;
  transition: 285ms ease-in-out;
  background: rgba(28, 219, 134, 0.3);
  color: #00141d;
}

.send-application:hover {
  background: rgba(28, 219, 134, 0.4);
  transition: 285ms ease-in-out;
}

.send-application:active {
  transition: 155ms ease-in-out;
  background: rgba(28, 219, 134, 0.5);
  border: 4px solid #1cdb86;
}

.job-application-form-width {
  width: 100% !important;
}

.uppload-resume {
  border: 3px dashed #c0c0c0;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eceef5;
  color: #00141d;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  transition: 285ms ease-in-out;
}

.uppload-resume:hover {
  background-color: #dfe0e5;
  transition: 285ms ease-in-out;
  border: 3px solid #1cdb86;
  cursor: pointer;
}

.acceptable-note {
  color: #6b6e71;
  font-size: 14px;
  margin-top: 2px;
}

#resume-upload {
  position: absolute;
  width: 100%;
  height: 45px;
  opacity: 0;
  cursor: pointer;
}

.resume-top-mrg {
  margin-top: 15px !important;
}

.element-spacing-jobs {
  margin-top: 12px;
}

.file-preview {
  background: #eceef5;
  border: 2px solid #c0c0c0;
  border-radius: 5px;
  padding: 5px;
}

.apply-job-res-message {
  display: none;
}

@media only screen and (max-width: 1500px) and (min-width: 1160px) {
  .Apply-form {
    width: 460px !important;
  }
}

@media only screen and (max-width: 1160px) and (min-width: 950px) {
  .Apply-form {
    width: 420px !important;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .job-application-form-width {
    width: 70%;
  }

  .Apply-form {
    position: sticky;
    top: 8px;
    width: 480px !important;
  }

  .element-spacing-jobs {
    margin-top: 9px;
  }

  .form-field {
    font-size: 13px;
    height: 40px;
  }

  .uppload-resume,
  #resume-upload {
    height: 43px;
  }

  .job-textarea {
    min-height: 130px !important;
  }

  .send-application {
    margin-top: 15px;
    font-size: 17px;
    height: 45px;
  }

  .input-form-name {
    font-size: 12px;
    line-height: 12px;
  }
}

@media only screen and (max-width: 950px) {
  .Apply-form {
    display: none;
  }

  #open-positions {
    width: 100% !important;
  }
}
