.case-studies-data-list {
  padding-top: 40px;
}

.case-studies-data-list span {
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
  color: #00141d;
  text-align: center;
  max-width: 1000px;
}

.case-studies-mapping {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
  max-width: 1300px;
}

.bottom-case-studies {
  width: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  margin-top: 45px;
  text-align: center;
  background-color: #00141d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-case-studies p {
  font-size: 25px;
  line-height: 32px;
  font-weight: normal;
  max-width: 70%;
  color: #fff !important;
}

.case-studies-project {
  width: 400px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid #f5f6fa;
  height: 585px;
  padding-bottom: 15px;
  position: relative;
}

.industry-tag {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00141d;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  color: #ffff;
}

.project-data {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
}

.connect-button-case {
  margin-top: 10px;
  color: rgb(0, 20, 29);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: 15px;
  background-color: #f5f6fa;
  min-height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  transition: 285ms ease-in-out;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
}

.connect-button-case:hover {
  background-color: #dee0e7;
  transition: 285ms ease-in-out;
}

.connect-button-case p {
  margin-right: 9px;
  font-size: 20px !important;
  font-weight: 600;
  margin-top: 0px !important;
  line-height: 20px !important;
  text-align: center !important;
}

.connect-button-icon-case {
  font-size: 20px;
  transition: 285ms ease-in-out;
}

.case-studies-project img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.case-studies-project h2 {
  font-size: 24px;
  line-height: 27px;
  text-align: left;
  margin-top: 15px;
  font-weight: bold;
}

.case-studies-project-desc {
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
}

@media only screen and (max-width: 1350px) and (min-width: 1000px) {
  .case-studies-mapping {
    width: unset;
  }
}

@media only screen and (max-width: 1000px) {
  .case-studies-mapping {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .case-studies-project {
    display: inline;
    width: 356px;
  }

  .bottom-case-studies p {
    max-width: 95% !important;
  }
}

@media only screen and (max-width: 752px) {
  .case-studies-mapping {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
  }
  .case-studies-project {
    display: flex;
    width: 95%;
  }
}

@media only screen and (max-width: 600px) {
  .case-studies-data-list > span {
    width: 90%;
    font-size: 35px;
  }
  .case-studies-mapping {
    width: 100%;
  }
  .bottom-case-studies p {
    font-size: 23px;
    width: 90%;
  }
}

@media only screen and (max-width: 490px) and (max-width: 450px) {
  .case-studies-project {
    height: 600px;
  }
}

@media only screen and (max-width: 450px) and (max-width: 390px) {
  .case-studies-project {
    height: 630px;
  }
}

@media only screen and (max-width: 390px) and (max-width: 350px) {
  .case-studies-project {
    height: 650px;
  }
}
