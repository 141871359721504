.internship-title {
  color: #fff;
  font-size: 45px;
  line-height: 1;
  text-align: left;
  font-weight: bold;
  margin-bottom: 40px;
}

#internship {
  width: 100%;
  height: 90vh;
  background-color: #00141d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.internship-backdrop {
  width: 90%;
  height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.internship-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
}

.internship-animation {
  width: 37%;
}

.internship-animation svg {
  cursor: default;
}

.internship-txt {
  color: white;
  font-size: 20px;
  line-height: 27px;
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Apply-internship {
  padding: 10px 20px;
  word-spacing: 5px;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  background-color: #1cdb86;
  border-radius: 8px;
  border: 4px solid #1cdb86;
  margin-top: 25px;
  transition: 285ms ease-in-out;
}

.Apply-internship:hover {
  color: #1cdb86;
  background-color: transparent;
  transition: 285ms ease-in-out;
}

@media only screen and (max-width: 1600px) {
  #internship {
    min-height: 100vh;
    height: fit-content;
  }

  .internship-backdrop {
    width: 95%;
  }

  .internship-txt {
    font-size: 19px;
    line-height: 25px;
    margin-top: 11px;
    margin-bottom: 11px;
  }

  .internship-title {
    margin-bottom: 25px;
  }

  .Apply-internship {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 950px) {
  .internship-backdrop {
    justify-content: center;
    flex-direction: column-reverse;
  }

  .internship-text {
    width: 100%;
  }

  .internship-title {
    margin-top: 20px;
  }

  .internship-text {
    justify-content: center;
    align-items: center;
  }
  .internship-txt {
    max-width: 95%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 950px) {
  .internship-animation {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .internship-animation {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .internship-title {
    font-size: 35px;
    margin-bottom: 30px;
  }

  .internship-txt {
    font-size: 18px;
    line-height: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .Apply-internship {
    font-size: 17px;
    margin-top: 20px;
  }
}