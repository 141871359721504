.industries {
  width: 100%;
  padding-top: 135px;
  padding-bottom: 135px;
  background-color: #00141d;
  border-top: 6px dashed #1cdb86;
  border-bottom: 6px dashed #1cdb86;
}

.icon-wrp {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 2px solid #1cdb86;
  display: flex;
  justify-content: center;
  align-items: center;
}

.industry-icon {
  font-size: 50px;
  color: #1cdb86;
}

.industries-headline {
  font-size: 50px;
  font-weight: bold;
  color: rgb(0, 20, 29);
  margin-bottom: 20px;
  width: 100% !important;
  text-align: center;
}

.btm-industry-devider {
  margin-bottom: 60px;
}

.industries-desc {
  max-width: 750px;
  text-align: center;
}

.industrie-descr {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.industries-we-serve-headline {
  max-width: 85% !important;
  font-size: 48px !important;
  line-height: 1;
  text-align: center;
}

.industry-organizations-desc {
  max-width: 1200px;
  margin-top: 28px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 50px;
}

.icon-blocks-wrp-industries {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 850px;
  margin-top: 50px;
}

.industry-item-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.industry-item-block h2 {
  width: 170px;
  font-size: 19px;
  text-align: center;
}

@media only screen and (max-width: 1550px) and (min-width: 1000px) {
  .industries-we-serve-headline {
    max-width: 85% !important;
    font-size: 40px !important;
  }

  .industry-organizations-desc {
    max-width: 900px;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 800px) {
  .industry-organizations-desc {
    max-width: 90%;
    font-size: 20px;
    line-height: 27px;
  }

  .industries-we-serve-headline {
    font-size: 40px !important;
  }

  .icon-blocks-wrp-industries {
    width: 90%;
    margin-top: 100px;
  }

  .icon-wrp {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  .industry-icon {
    font-size: 40px;
  }

  .industries-headline {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 550px) {
  .industry-organizations-desc {
    max-width: 90%;
    font-size: 20px;
    line-height: 27px;
  }

  .industries-we-serve-headline {
    font-size: 35px !important;
  }

  .icon-blocks-wrp-industries {
    width: 90%;
    margin-top: 100px;
    text-align: center;
    display: block !important;
    margin-top: 0px;
  }

  .industry-item-block {
    display: inline-flex !important;
    width: 28% !important;
    margin: 20px;
  }

  .icon-wrp {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  .industry-icon {
    font-size: 40px;
  }

  .industries-headline {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 549px) and (min-width: 400px) {
  .industry-organizations-desc {
    max-width: 90%;
    font-size: 20px;
    line-height: 27px;
  }

  .industries-we-serve-headline {
    font-size: 35px !important;
  }

  .icon-blocks-wrp-industries {
    width: 95%;
    margin-top: 100px;
    text-align: center;
    display: block !important;
    margin-top: 0px;
  }

  .industry-item-block {
    display: inline-flex !important;
    width: 28% !important;
    margin: 15px;
  }

  .icon-wrp {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  .industry-icon {
    font-size: 40px;
  }

  .industries-headline {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .industry-organizations-desc {
    max-width: 90%;
    font-size: 21px;
    line-height: 27px;
  }

  .industries-we-serve-headline {
    font-size: 35px !important;
  }

  .industries .icon-blocks-wrp-industries {
    width: 95%;
    text-align: center;
    display: block !important;
  }

  .industries .icon-blocks-wrp-industries:nth-child(3) {
    margin-top: 0;
  }

  .industry-item-block {
    display: inline-flex !important;
    width: 35% !important;
    margin: 15px;
  }

  .icon-wrp {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  .industry-icon {
    font-size: 40px;
  }

  .industries-headline {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .industries {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .industry-item-block h2 {
    font-size: 17px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .industries {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}
