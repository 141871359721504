.job-openings-headline {
  font-size: 50px;
  line-height: 1;
  font-weight: bold;
  color: #00141d;
  text-align: center;
}

.openings-description {
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #00141d;
  width: 60%;
}

.openings-button {
  background-color: #1dc579;
  color: #fff;
  font-size: 22px;
  padding: 15px 30px;
  border-radius: 10px;
  margin-top: 25px;
  font-weight: 600;
  border: 3px solid #1dc579;
  transition: 285ms ease-in-out;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.life-at-obelus-btn {
  background-color:#00141d !important;
  border: 3px solid #00141d !important;
  margin-left: 10px;
}

.life-at-obelus-btn:hover {
  background-color: #fff !important;
  color: #00141d !important;
  transition: 285ms ease-in-out;
}

.life-at-obelus-btn, .openings-button {
  width: 265px;
}

.openings-button:hover {
  background-color: #fff;
  color: #1dc579;
  transition: 285ms ease-in-out;
}

.job-openings-bg {
  background-color: #f5f6fa;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1550px) and (min-width: 800px) {
  .job-openings-headline {
    font-size: 40px;
  }
  
  .openings-description {
    font-size: 20px;
    line-height: 24px;
    margin-top: 15px;
    width: 90% !important;
  }
  
  .openings-button {
    font-size: 20px;
    padding: 9px 21px;
    margin-top: 20px;
  }

  .openings-description {
    font-size: 20px;
    line-height: 24px;
    width: 80%;
  }
}

@media only screen and (max-width: 800px) and (min-width: 500px) {
  .job-openings-headline {
    font-size: 35px;
  }
  
  .openings-description {
    font-size: 20px;
    margin-top: 15px;
    width: 90% !important;
  }
  
  .openings-button {
    font-size: 21px;
    padding: 9px 21px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 540px) {
  .job-openings-headline {
    font-size: 35px;
    max-width: 85%;
  }
  
  .openings-description {
    font-size: 20px;
    margin-top: 14px;
    width: 90% !important;
  }
  
  .openings-button {
    font-size: 20px;
    padding: 9px 20px;
    margin-top: 17px;
  }

  .job-openings-button-wrappers {
    flex-direction: column;
  }

  .life-at-obelus-btn {
    margin-left: 0px;
  }
}
