.sitemap {
  width: 100%;
  height: fit-content;
  background-color: #00141d;
  padding-top: 50px;
}

.sitemap-txt {
  margin-top: 8px;
  font-size: 20px;
  line-height: 28px;
  transition: 285ms ease-in-out;
  border-bottom: 1px solid transparent;
}

.sitemap-txt:hover {
  transition: 285ms ease-in-out;
  border-bottom: 1px solid #1cdb86;
}

.logo-sitemap {
  width: 300px;
}

.green-line {
  background-color: #1cdb86;
}

.shaped-div {
  width: 70%;
  height: 2px;
  background-color: #1cdb86;
  margin-top: 70px;
}

.social-media-block-sitemap {
   padding: 10px;
   border-radius: 8px;
   transition: 285ms ease-in-out;
}

.social-media-block-sitemap:hover {
  transition: 285ms ease-in-out;
  transform: scale(1.1);
  background-color: #031e2b;
}


.site-map-devider {
  width: 1px;
  height: 70px;
}

.footer {
  width: 100%;
  height: 100px;
}

.slogan-with {
  max-width: 300px;
}

@media only screen and (max-width: 1190px) {
  .sitemap-blocks {
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) and (min-width: 300px) {
  .sitemap-blocks {
    flex-direction: column !important;
    width: 90%;
  }

  .sitemap-block {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    width: 100%;
    margin-top: 20px;
  }

  .contact-sitemap {
    margin-left: 0px !important;
  }

  footer {
    text-align: center;
  }

  .shaped-div {
    width: 90%;
  }

  .sitemap-contact-spacing {
    margin-top: 10px !important;
  }

  .sitemap-contact-spacing p {
    font-size: 16px;
    padding: 0px !important;
  }

  .copyright {
    width: 90%;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .logo-sitemap {
    width: 250px;
  }

  .sitemap-txt {
    margin-top: 8px;
    font-size: 17px;
    line-height: 21px;
  }

  .shaped-div {
    width: 77%;
  }
}

@media only screen and (max-width: 660px) {
  .site-links,
  .site-contact {
    flex-direction: column;
  }
  .site-links a,
  .site-contact > div {
    margin-left: 0;
  }

  .site-contact > div:nth-child(1) {
    margin-top: 8px;
  }

  .site-contact > div:nth-child(1n + 2) {
    margin-top: 30px;
  }
}
