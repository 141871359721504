.case-studies-introduction {
  background-color: #f5f6fa;
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-wrapper-case {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}

.casestudies-banner-animation {
  width: 45%;
}

.casestudies-txt {
  width: 50%;
  animation: fadeInJobs 385ms ease-in-out;
}

.casestudies-txt h1 {
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #00141d;
}

.casestudies-txt p {
  font-size: 22px;
  line-height: 29px;
  color: #00141d;
  text-align: center;
  max-width: 90%;
}

@media only screen and (max-width: 1100px) {
  .casestudies-banner-animation {
    width: 60%;
  }
  .casestudies-txt h1 {
    font-size: 59px;
  }
}

@media only screen and (max-width: 900px) {
  .casestudies-banner-animation {
    width: 100%;
  }
  .casestudies-txt {
    width: 100%;
  }
  .casestudies-txt h1 {
    text-align: center;
    font-size: 45px;
  }
  .casestudies-txt p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .casestudies-banner-animation {
    width: 80%;
  }
  .casestudies-txt {
    width: 90%;
  }
  .casestudies-txt h1 {
    text-align: center;
    font-size: 35px;
  }
  .casestudies-txt p {
    max-width: 100%;
    font-size: 20px;
  }
  .case-studies-introduction {
    height: 100vh;
  }
  .data-wrapper-case {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
  }
}

@media only screen and (max-width: 500px) {
  .casestudies-banner-animation {
    width: 100%;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .case-studies-introduction, .case-study-banner {
    min-height: 80vh;
    height: fit-content;
    padding-top: 35px;
    padding-bottom: 35px;
  }
}