/*
  --dark: #00141d
  --gray: #f5f6fa
*/

.animated-banner {
  min-height: 100vh;
  height: fit-content;
  width: 100%;
  background-color: #00141d;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
}

.animated-banner-content {
  width: 80%;
  min-height: calc(100vh - 60px);
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.animated-banner-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 45%;
}

.animated-banner-text p {
  font-size: 100px;
  line-height: 100px;
  font-weight: bold;
  color: #fff;
  z-index: 9;
}

.slogan-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 60px;
  line-height: 70px;
  font-weight: bold;
  width: 100%;
  color: #fff;
  z-index: 9;
  margin-top: 15px;
}

.slogan-wrapper h1 {
  font-size: 35px;
  line-height: 35px;
  color: #1cdb86;
  margin-top: 15px;
  font-weight: normal;
}

.banner-animation {
  width: 40%;
  z-index: 1;
}

.scroll-down-banner-2 {
  border: 3px solid white;
  padding: 10px;
  border-radius: 50%;
  font-size: 50px !important;
  z-index: 9;
  transition: 285ms ease-in-out;
}

.scroll-down-banner-2:hover {
  background-color: #fff;
  color: #00141d;
  transition: 285ms ease-in-out;
}

.star {
  color-scheme: light !important;
  color: #06202b !important;
  z-index: 1;
}

.star,
.star-1,
.star-2,
.star-3,
.square-1,
.square-2,
.line-1 {
  color-scheme: light !important;
  color: #06202b !important;
}

.star-1 {
  font-size: 150px;
  position: absolute;
  top: 30%;
  left: 30%;
  transform: rotate(90deg);
  color-scheme: light !important;
  color: #06202b !important;
}

.star-2 {
  font-size: 350px;
  position: absolute;
  top: 0px;
  left: -150px;
  transform: rotate(90deg);
  color-scheme: light !important;
  color: #06202b !important;
}

.star-3 {
  font-size: 350px;
  position: absolute;
  bottom: 0px;
  right: -150px;
  transform: rotate(90deg);
  color-scheme: light !important;
  color: #06202b !important;
}

.circle-1 {
  font-size: 350px;
  position: absolute;
  top: -150px;
  right: -150px;
  color-scheme: light !important;
  color: #06202b !important;
}

.circle-2 {
  font-size: 300px;
  position: absolute;
  bottom: -150px;
  left: -100px;
  color-scheme: light !important;
  color: #06202b !important;
}

.square-1 {
  font-size: 150px;
  position: absolute;
  bottom: 150px;
  right: 50%;
  transform: rotate(45deg);
  color-scheme: light !important;
  color: #06202b !important;
}

.square-2 {
  font-size: 150px;
  position: absolute;
  top: 100px;
  right: 50%;
  transform: rotate(45deg);
  color-scheme: light !important;
  color: #06202b !important;
}

.line-1 {
  font-size: 200px;
  position: absolute;
  top: 0px;
  left: 20%;
  transform: rotate(-45deg);
  color-scheme: light !important;
  color: #06202b !important;
}

.fadeUp {
  animation: fadeUp 285ms ease-in-out;
}

@keyframes fadeUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animated-banner-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  z-index: 899;
  position: relative;
}

.animated-banner-btn {
  font-size: 24px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 180px;
  text-align: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  margin: 10px;
  transition: 285ms ease-in-out;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: row;
}

.link-icon {
  font-size: 24px;
  margin-left: 5px;
}

.animated-banner-btn:hover {
  color: #00141d;
  transition: 285ms ease-in-out;
  background-color: #fff;
}

@media only screen and (max-width: 1100px) {
  .animated-banner-content {
    width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .animated-banner-text {
    width: 100%;
  }
  .animated-banner-text .slogan-wrapper p {
    font-size: 55px;
    line-height: 60px;
  }
  .animated-banner-text .slogan-wrapper h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .banner-animation {
    width: 100%;
  }

  .line-1 {
    display: none;
  }

  .animated-banner-buttons {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    width: 100%;
  }
}

@media only screen and (max-height: 700px) {
  .animated-banner-text .slogan-wrapper {
    margin-top: 150px !important;
  }
}

@media only screen and (max-width: 700px) {
  .animated-banner-content {
    justify-content: center;
    flex-direction: column;
  }
  .animated-banner-text .slogan-wrapper {
    margin-top: 80px;
  }
  .animated-banner-text .slogan-wrapper p {
    font-size: 70px;
    line-height: 67px;
  }
  .banner-animation {
    width: 65%;
  }

  .slogan-wrapper {
    justify-content: center !important;
    align-items: center !important;
  }

  .slogan-wrapper p,
  .slogan-wrapper h1 {
    text-align: center;
  }

  .square-2 {
    display: none;
  }

  .animated-banner-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {

  .banner-animation {
    width: 80%;
  }

  .slogan-wrapper {
    justify-content: center !important;
    align-items: center !important;
  }

  .slogan-wrapper h1,
  .slogan-wrapper p {
    text-align: center;
  }

  .square-1,
  .square-2,
  .star-2 {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .banner-animation {
    width: 100%;
  }

  .star,
  .star-1,
  .star-2,
  .star-3,
  .square-1,
  .square-2,
  .line-1 {
    color: #06202b !important;
  }
}

@media (prefers-color-scheme: dark) {
  .star,
  .star-1,
  .star-2,
  .star-3,
  .square-1,
  .square-2,
  .line-1 {
    color: #06202b !important;
  }

  .star-1 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .star-2 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .star-3 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .circle-1 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .circle-2 {
    color-scheme: light !important;
    color: #06202b !important;
  }
}

@media (prefers-color-scheme: light) {
  .star,
  .star-1,
  .star-2,
  .star-3,
  .square-1,
  .square-2,
  .line-1 {
    color: #06202b !important;
  }

  .star-1 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .star-2 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .star-3 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .circle-1 {
    color-scheme: light !important;
    color: #06202b !important;
  }

  .circle-2 {
    color-scheme: light !important;
    color: #06202b !important;
  }
}
