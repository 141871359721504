.job-highlighted {
  width: 100%;
  padding: 10px;
  border-radius: 2px;
  background-color: #eceef5;
  color: #00141d;
  font-size: 16px;
  border-radius: 5px;
}

.clickable-job {
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.clickable-job:hover {
  background: #dbdee7cc;
  transition: 285ms ease-in-out;
}

.position-title {
  font-size: 22px;
  color: #00141d;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 2px solid #1cdb86;
  margin-bottom: 5px;
}

.tick-icon {
  display: block;
  width: 15px;
  min-width: 15px;
}

.Apply-now-button {
  padding: 9px 15px;
  border: 3px solid #00141d;
  outline: none;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 15px;
  background-color: #00141d;
  border-radius: 3px;
  color: white;
  transition: 285ms ease-in-out;
  font-weight: bold;
}

.Apply-now-button:hover {
  background-color: white;
  color: #00141d;
  transition: 285ms ease-in-out;
}

@media only screen and (max-width: 950px) {
  .job-market {
    width: 95% !important;
    padding-top: 25px;
  }
}