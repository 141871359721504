.technologies {
  padding-top: 95px;
}

.green-devider {
  width: 150px;
  height: 10px;
  background-color: #1cdb86;
  border-radius: 10px;
  margin-bottom: 30px;
}

.technology-text {
  font-size: 20px;
  line-height: 24px;
  width: 1000px;
}

.service-description {
  font-size: 20px;
  line-height: 24px;
  max-width: 1000px;
  text-align: center;
  color: #00141d;
  font-weight: 600;
}

.technology-block {
  width: 300px;
  height: 350px;
  border-top: 5px solid #1cdb86;
  background-color: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
}

.technology-icon {
  font-size: 70px;
  color: #1cdb86;
}

.technology-name {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
  height: 80px;
}

.technology-descr {
  background-color: #f5f6fa;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.technology-descr h2 {
  max-width: 750px;
  text-align: center;
}

.margin-top-des {
  height: 20px;
  width: 1px;
}

.technology-wrp {
  width: 1280px;
  margin-top: 48px;
  margin-bottom: 60px;
}

.service-block {
  display: inline-block !important;
  vertical-align: top;
  border-radius: 7px;
  background-color: #f5f6fa;
  border-top: 6px solid #1cdb86;
  width: 300px;
  margin: 10px;
  overflow: hidden;
  cursor: pointer;
  height: 300px;
}

.service-content {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.service-block h2 {
  font-size: 23px;
  font-weight: bold;
  color: #00141d;
  line-height: 29px;
  width: 90%;
  margin-top: 20px;
  text-align: center;
}

.service-icon {
  font-size: 60px;
  line-height: 60px;
  height: 60px;
  display: flex;
}

.showing-related-data {
  width: 100%;
  height: 300px;
  background-color: #00141d;
  color: white;
  transform: translateY(100%);
  transition: 285ms ease-in-out;
}

.technology-title {
  font-size: 48px;
  line-height: 1;
  text-align: center;
  max-width: 80%;
}

.showing-related-data li {
  margin-top: 10px;
  line-height: 24px !important;
}

.service-block:hover .showing-related-data {
  transition: 385ms ease-in-out;
  transform: translateY(-100%);
}

.frameworks {
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.framework {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  width: calc(12% - 20px);
}

.framework img {
  height: 60px;
  width: fit-content;
  filter: grayscale(1);
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.framework img:hover {
  filter: grayscale(0);
  transition: 285ms ease-in-out;
}

.framework p {
  font-size: 23px;
  line-height: 29px;
  font-weight: bold;
  margin-top: 25px;
}

.service-mobile-wrapper {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  height: 100%;
  min-height: 100vh !important;
  width: 100%;
  z-index: 9999;
  display: none;
}

.service-mobile-popup {
  background: #ffffff;
  width: 90%;
  height: 400px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}

.service-mobile-popup svg {
  float: right;
}
.service-mobile-popup .service-mobile-desc {
  margin-top: 50px;
  padding: 10px;
  overflow-y: auto;
  height: 300px;
}
.service-mobile-popup .service-mobile-desc h2 {
  font-size: 20px;
  font-weight: bold;
  color: #00141d;
  line-height: 29px;
}

.service-mobile-popup .service-mobile-desc p {
  font-size: 18px;
  color: #00141d;
  margin-top: 20px;
}

.service-mobile-open {
  animation: openServiceMobile 0.2s ease-in-out;
}

.service-mobile-close {
  animation: closeServiceMobile 0.2s ease-in-out;
}

.framwork-image {
  height: 60px;
  object-fit: contain;
}

@keyframes openServiceMobile {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closeServiceMobile {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1750px) and (min-width: 1460px) {
  .technology-wrp {
    margin-top: 48px;
  }

  .service-block h2 {
    font-size: 20px;
    line-height: 27px;
  }

  .frameworks {
    width: 90%;
  }

  .technology-block {
    width: 250px;
    height: 280px;
    font-size: 22px;
  }

  .technology-icon {
    font-size: 50px;
    color: #1cdb86;
  }

  .technology-name {
    font-size: 23px;
    text-align: center;
    margin-top: 20px;
    max-width: 80%;
  }

  .technology-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1460px) and (min-width: 1180px) {
  .technology-wrp {
    width: 1080px;
  }

  .service-block {
    width: 250px;
    height: 250px;
  }

  .service-content {
    height: 250px;
  }

  .showing-related-data {
    height: 250px;
  }

  .service-block h2 {
    font-size: 20px;
    line-height: 27px;
  }

  .frameworks {
    width: 97%;
  }

  .framework {
    margin: 10px;
    width: calc(12% - 20px);
  }

  .framework img {
    height: 50px;
  }

  .framework p {
    font-size: 19px;
    line-height: 25px;
  }

  .technology-block {
    width: 250px;
    height: 280px;
  }

  .service-block h2 {
    font-size: 20px;
    line-height: 27px;
  }

  .service-icon {
    font-size: 50px;
    height: 50px;
    line-height: 50px;
  }

  .technology-icon {
    font-size: 50px;
    color: #1cdb86;
  }

  .technology-name {
    font-size: 22px;
    text-align: center;
    margin-top: 30px;
    height: 60px;
    max-width: 90%;
  }

  .technology-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1180px) and (min-width: 1050px) {
  .frameworks {
    width: 96%;
  }

  .technology-wrp {
    width: 920px;
  }

  .service-block {
    width: 210px;
    height: 210px;
  }

  .service-content {
    height: 210px;
  }

  .showing-related-data {
    height: 210px;
  }

  .framework {
    align-self: center;
    margin: 10px;
    width: calc(18% - 20px);
  }

  .framework img {
    height: 50px;
  }

  .framework p {
    font-size: 19px;
    line-height: 25px;
  }

  .technology-block {
    width: 240px;
    height: 280px;
  }
  .technology-icon {
    font-size: 50px;
    color: #1cdb86;
  }

  .service-block h2 {
    font-size: 20px;
    line-height: 27px;
  }

  .service-icon {
    font-size: 50px;
    height: 50px;
    line-height: 50px;
  }

  .technology-name {
    font-size: 22px;
    text-align: center;
    margin-top: 30px;
    height: 60px;
    max-width: 90%;
  }

  .technology-text {
    font-size: 20px;
    line-height: 27px;
    max-width: 85% !important;
  }

  .service-description {
    font-size: 20px;
    line-height: 27px;
  }

  .technology-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1050px) and (min-width: 950px) {
  .frameworks {
    width: 96%;
  }

  .technology-wrp {
    width: 900px;
  }

  .service-block {
    width: 280px;
    height: 280px;
  }

  .service-content {
    height: 280px;
  }

  .showing-related-data {
    height: 280px;
  }

  .service-icon {
    font-size: 40px;
    height: 40px;
    line-height: 40px;
  }

  .framework {
    align-self: center;
    margin: 10px;
    width: calc(18% - 20px);
  }

  .framework img {
    height: 50px;
  }

  .framework p {
    font-size: 19px;
    line-height: 25px;
  }

  .service-block h2 {
    font-size: 20px;
    line-height: 27px;
  }

  .technology-block {
    width: 220px;
    height: 240px;
  }

  .showing-related-data p {
    font-size: 16px;
    line-height: 19px;
  }

  .technology-icon {
    font-size: 50px;
    color: #1cdb86;
  }

  .technology-name {
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    height: 60px;
    max-width: 90%;
  }

  .technology-text {
    font-size: 20px;
    line-height: 27px;
    max-width: 85% !important;
  }

  .service-description {
    font-size: 20px;
    line-height: 27px;
  }

  .technology-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 950px) and (min-width: 650px) {

  .technology-wrp {
    width: 640px;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .service-block {
    width: 300px;
    height: 300px;
  }

  .service-content {
    height: 300px;
  }

  .showing-related-data {
    height: 300px;
  }

  .framework {
    align-self: center;
    margin: 10px;
    width: calc(22% - 20px);
  }

  .framework img {
    height: 50px;
  }

  .framework p {
    font-size: 19px;
    line-height: 25px;
  }

  .frameworks {
    width: 100%;
  }

  .service-block h2 {
    font-size: 20px;
    line-height: 26px;
  }


  .technology-block {
    width: 300px;
    display: inline-flex !important;
    height: 320px;
    margin: 10px;
  }

  .showing-related-data {
    text-align: left;
  }

  .showing-related-data p {
    font-size: 18px;
    line-height: 21px;
  }

  .technology-icon {
    font-size: 50px;
    color: #1cdb86;
  }

  .technology-name {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    height: 60px;
    max-width: 90%;
  }

  .technology-text {
    font-size: 20px;
    line-height: 27px;
    max-width: 85% !important;
  }

  .service-description {
    font-size: 20px;
    line-height: 27px;
  }

  .technology-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 650px) and (min-width: 530px) {
  .technology-wrp {
    width: 100%;
    display: block !important;
    margin-right: 15px !important;
    margin-left: 15px !important;
    text-align: center;
    width: 520px;
  }

  .service-block {
    width: 240px;
    height: 240px;
  }

  .service-content {
    height: 240px;
  }

  .showing-related-data {
    height: 240px;
  }

  .frameworks {
    width: 100%;
  }

  .framework {
    align-self: center;
    margin: 10px;
    width: calc(25% - 20px);
  }

  .framework img {
    height: 50px;
  }

  .framework p {
    font-size: 19px;
    line-height: 25px;
  }

  .technology-block {
    width: 240px;
    display: inline-flex !important;
    height: 280px;
    margin: 10px;
  }

  .showing-related-data {
    text-align: left;
  }

  .showing-related-data p {
    font-size: 18px !important;
    line-height: 18px !important;
    padding: 3px !important;
    margin: 0px !important;
  }

  .technology-icon {
    font-size: 50px;
    color: #1cdb86;
  }

  .technology-name {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    height: 60px;
    max-width: 90%;
  }

  .technology-text {
    font-size: 20px;
    line-height: 27px;
    max-width: 85% !important;
  }

  .service-description {
    font-size: 20px;
    line-height: 27px;
  }

  .technology-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 530px)  {
  .technology-wrp {
    width: 100%;
    display: block !important;
    margin-right: 15px !important;
    margin-left: 15px !important;
    text-align: center;
  }

  .framework {
    align-self: center;
    margin: 10px;
    width: calc(50% - 20px);
  }

  .framework img {
    height: 50px;
  }

  .framework p {
    font-size: 19px;
    line-height: 25px;
  }

  .frameworks {
    width: 100%;
  }

  .service-icon {
    font-size: 40px;
  }

  .service-block h2 {
    font-size: 16px;
    line-height: 20px;
    width: 96%;
  }

  .service-block:hover .showing-related-data {
    transform: translateY(100%);
  }

  .technologies {
    padding-top: 45px !important;
  }

  .technology-block {
    width: 290px;
    display: inline-flex !important;
    height: 320px;
    margin: 0px;
    margin-bottom: 25px !important;
  }

  .showing-related-data {
    text-align: left;
  }

  .showing-related-data p {
    font-size: 18px !important;
    line-height: 18px !important;
    padding: 3px !important;
    margin: 0px !important;
  }

  .service-description {
    font-size: 20px;
    line-height: 27px;
    max-width: 85% !important;
    text-align: center;
  }

  .technology-icon {
    font-size: 50px;
    color: #1cdb86;
  }

  .technology-name {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    height: 60px;
    max-width: 90%;
  }

  .technology-text {
    font-size: 20px;
    line-height: 27px;
    max-width: 90%;
  }

  .technology-title {
    font-size: 35px;
    line-height: 1;
    text-align: center;
    max-width: 80%;
  }

  .technology-descr {
    margin-top: -30px !important;
  }
}

@media only screen and (max-width: 530px)  and (min-width: 420px) {
  .service-block {
    margin: 4px;
    width: 200px;
    border-radius: 5px;
    height: 200px;
    border-top-width: 3px;
  }
  
  .service-content {
    height: 200px;
  }
}


@media only screen and (max-width: 420px)  and (min-width: 390px) {
  .service-block {
    margin: 4px;
    width: 170px;
    border-radius: 5px;
    height: 170px;
    border-top-width: 3px;
  }
  
  .service-content {
    height: 170px;
  }
}


@media only screen and (max-width: 390px)  and (min-width: 360px) {
  .service-block {
    margin: 4px;
    width: 160px;
    border-radius: 5px;
    height: 160px;
    border-top-width: 3px;
  }
  
  .service-content {
    height: 160px;
  }
}

@media only screen and (max-width: 360px)  and (min-width: 320px) {
  .service-block {
    margin: 4px;
    width: 150px;
    border-radius: 5px;
    height: 150px;
    border-top-width: 3px;
  }
  
  .service-content {
    height: 150px;
  }
}

@media only screen and (max-width: 320px) {
  .service-block {
    margin: 4px;
    width: 250px;
    border-radius: 5px;
    height: 250px;
    border-top-width: 3px;
  }
  
  .service-content {
    height: 250px;
  }
}