.burger-menu {
  position: fixed;
  background: #ffffff;
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  top: 0px;
  z-index: 99999;
}
.burger-menu .burger-header {
  margin-bottom: 15px;
}
.burger-menu .burger-header img {
  position: relative;
  top: 5px;
  left: 10px;
  width: 200px;
}
.burger-menu .burger-close {
  position: relative;
  top: 10px;
  right: 10px;
}
.burger-menu .burger-navlinks {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.burger-menu .burger-hr {
  height: 5px;
  width: 230px;
  background: #1cdb86;
  border-radius: 24px;
}

.burger-active {
  width: 95%;
  background: #1cdb86;
  color: #ffffff;
  font-size: 23px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px;
  font-weight: 700;
}

.burger-unactive {
  width: 95%;
  background: #ffffff;
  color: #00141d;
  font-size: 23px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px;
  font-weight: 700;
}

.hidden-burger {
  display: none;
}

.burger-visible {
  display: block;
}

.open-burger {
  display: block;
  animation: openBurger 0.25s ease-in-out forwards;
}

.close-burger {
  display: block;
  animation: closeBurger 0.25s ease-in-out forwards;
}

@keyframes openBurger {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes closeBurger {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
