/*
   IMPORT THIS FILE IN EACH COMPONENT FOR CASE STUDY
*/

.case-study-banner {
  height: 75vh;
  background-color: #f5f6fa;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.casestudies-banner-animation-formated {
  width: 35%;
}

.case-study-banner > .data-wrapper-case-studie {
  width: 75%;
}

.data-wrapper-case-studie {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}

.casestudies-txt-studie {
  width: 50%;
  animation: fadeInJobs 385ms ease-in-out;
}

.casestudies-txt-studie h2 {
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #00141d;
}

.casestudies-txt-studie p {
  font-size: 22px;
  line-height: 29px;
  color: #00141d;
  text-align: center;
  max-width: 90%;
}

.headline-for-case-study {
  font-size: 50px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

.case-study-desc {
  margin-top: 20px;
}

.normal-text {
  font-size: 22px;
  line-height: 29px;
  color: #00141d;
  text-align: justify;
  max-width: 90%;
}

.data-wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.data-text-wrp {
  width: 45%;
}

.data-wrp p {
  max-width: 100%;
}

.section-headline {
  color: #00141d;
  font-size: 40px;
  line-height: 47px;
  font-weight: 700;
}

.text-space {
  margin-top: 25px;
}

.data-picture {
  width: 45%;
  border-radius: 20px !important;
  overflow: hidden;
  cursor: none !important;
}

.data-picture-2 {
  width: 30%;
  border-radius: 20px !important;
  overflow: hidden;
  cursor: none !important;
}

.data-picture-3 {
  width: 35%;
  border-radius: 20px !important;
  overflow: hidden;
  cursor: none !important;
}

.remove-margs-data-wrp {
  margin-top: 0px;
  margin-bottom: 0px;
}

.industry-tag-case-study {
  background-color: #00141d;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 22px;
  margin-top: 20px;
  font-weight: 600;
  min-width: unset !important;
  max-width: unset !important;
  width: unset !important;
}

.industry-tag-case-study span {
  color: #1cdb86;
}

.industry-tag-case-study p {
  color: #fff !important;
  margin-left: 5px;
  min-width: unset !important;
  max-width: unset !important;
  width: unset !important;
}

.override-case-study-bottom h2 {
  max-width: 60%;
}

.override-case-study-bottom {
  margin-top: 0px !important;
}

.bottom-case-studies {
  width: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  margin-top: 45px;
  text-align: center;
  background-color: #00141d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-case-studies p {
  font-size: 25px;
  line-height: 32px;
  font-weight: normal;
  max-width: 70%;
  color: #fff !important;
}

@media only screen and (max-width: 850px) {
  .bottom-case-studies p {
    max-width: 95% !important;
  }

  .casestudies-txt-studie {
    animation: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .bottom-case-studies p {
    font-size: 23px;
    width: 90%;
  }
}

/* TMS CSS */

@media only screen and (max-width: 1600px) and (min-width: 700px) {
  .casestudies-txt-studie h2 {
    font-size: 45px;
    line-height: 52px;
  }

  .casestudies-txt-studie p {
    font-size: 20px;
    line-height: 27px;
    max-width: 100%;
  }

  .case-study-desc {
    margin-top: 20px;
  }

  .normal-text {
    font-size: 20px;
    line-height: 27px;
    max-width: 90%;
  }

  .data-wrp {
    width: 80%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .data-text-wrp {
    width: 45%;
  }

  .section-headline {
    font-size: 30px;
    line-height: 37px;
  }

  .override-case-study-bottom h2 {
    font-size: 20px !important;
    line-height: 27px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .tms-div {
    margin-top: 30px;
  }
  .tms-headline h2,
  .sb-headline h2,
  .tas-headline h2 {
    max-width: 100% !important;
  }
  .data-tms {
    flex-direction: column-reverse !important;
  }
  .data-picture-tms,
  .data-picture-sb {
    width: 80% !important;
  }

  .casestudies-txt-studie p {
    text-align: justify !important;
  }
}

@media only screen and (max-width: 800px) {
  .data-picture-tms,
  .data-picture-sb {
    width: 90% !important;
  }

  .casestudies-banner-animation-formated {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 755px) {
  .case-study-desc-tms {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 500px) {
  .data-picture-3 {
    width: 100%;
  }
  .data-picture-tms,
  .data-picture-sb {
    width: 100% !important;
  }

  .section-headline {
    width: 90%;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
  }

  .override-case-study-bottom h2 {
    font-size: 20px;
    line-height: 27px;
  }
}

/* Universal */

@media only screen and (max-width: 1400px) {
  .data-wrp {
    width: 90%;
  }
  .normal-text {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1350px) {
  .casestudies-banner-animation-formated {
    width: 90%;
  }
  .casestudies-txt-studie {
    width: 100%;
  }
  .casestudies-txt-studie h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .data-wrapper-case-studie {
    width: 90% !important;
  }
  .casestudies-banner-animation-formated {
    width: 100%;
  }
  .casestudies-txt-studie h2 {
    text-align: center;
    font-size: 40px;
  }
  .casestudies-txt-studie p {
    max-width: 100%;
    text-align: center;
  }
  .data-wrp {
    width: 90%;
    justify-content: center;
  }
  .text-part-1 .data-wrp {
    flex-direction: column;
  }

  .data-wrp {
    flex-direction: column;
  }

  .data-wrp-ebike,
  .data-wrp-sb,
  .data-wrp-tas {
    flex-direction: column-reverse;
  }

  .text-part-2 .data-wrp {
    flex-direction: column-reverse;
  }
  .data-text-wrp {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .data-picture,
  .data-picture-2 {
    margin-top: 25px;
  }
  .data-picture {
    width: 90%;
  }
  .data-picture-2 {
    width: 80%;
  }
  .sb-div {
    margin-top: 30px;
  }

  .text-part-2-responsive {
    margin-top: -50px;
    padding-top: 0px;
  }

  .text-part-1-qualified-rtc {
    margin-top: 25px;
  }

  .rm-t-marg {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 700px) {
  .casestudies-banner-animation-formated {
    width: 75%;
  }

  .case-study-banner {
    min-height: 100vh !important;
    height: fit-content !important;
    padding-bottom: 30px;
  }

  .casestudies-txt-studie {
    width: 90%;
  }
  .casestudies-txt-studie h2 {
    text-align: center;
    font-size: 35px;
  }
  .casestudies-txt-studie p {
    max-width: 100%;
    font-size: 20px;
  }
  .case-study-banner {
    height: 100vh;
  }
  .data-wrapper-case-studie {
    width: 100% !important;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;
  }
  .data-picture,
  .data-picture-2,
  .data-picture-3 {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .casestudies-banner-animation-formated {
    width: 80%;
  }
  .normal-text {
    font-size: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .casestudies-banner-animation-formated {
    width: 90%;
  }
  .casestudies-txt-studie h2 {
    line-height: initial;
  }
  .normal-text {
    font-size: 20px;
  }
  .bottom-case-studies p {
    max-width: 90%;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .case-study-banner {
    height: 80vh;
  }
}

@media only screen and (max-height: 800px) and (min-width: 500px) {
  .bottom-case-studies {
    margin-bottom: -30px;
  }
}
